<template>
  <div>
    <div class='row'>
      <div class='col-12'>
        <div class='row mb-md-2'>
          <div class='col-sm-12 col-md-6'></div>
          <div class='col-sm-12 col-md-6 text-md-right'>
            <button
              class='ml-1 btn btn-dark-blue btn-sm'
              @click='showCreateWidgetModal = true'
            >
              <i class='uil uil-plus'></i>
              Create Widget
            </button>
          </div>
        </div>
        <div class='row mb-md-2'>
          <div class='col-sm-12 col-md-6'>
            <div class='form-inline'>
              <label>
                Show&nbsp;
              </label>
              <b-form-select
                v-model='datatable.queries.per_page'
                class='ml-1 mr-1'
                size='sm'
                :options='[10, 15, 25, 50, 100]'
                @change='getWidgets'
              ></b-form-select>
            </div>
          </div>
          <div class='col-sm-12 col-md-6'>
            <div class='dataTables_filter text-md-right'>

              <label class='d-inline-flex align-items-center'>
                Search:
                <b-form-input
                  v-model='datatable.queries.search'
                  type='search'
                  placeholder='Search...'
                  class='form-control form-control-sm ml-2'
                ></b-form-input>
              </label>
              <button
                class='ml-1 btn btn-dark-blue btn-sm'
                @click='getWidgets'
              >
                <i class='uil uil-search-alt'></i>
              </button>
            </div>
          </div>
        </div>
        <div class='card'>
          <div class='card-body'>
            <template v-if='!loadingWidgets'>
              <div class='table-responsive mb-0 customer-table'>
                <b-table
                  :items='widgets'
                  :fields='datatable.columns'
                  :sort-by.sync='datatable.queries.sort_by'
                  :sort-desc.sync='datatable.queries.sort_desc'
                  :per-page='datatable.queries.per_page'
                  data-export-types="['csv']"
                  :no-local-sorting='true'
                  @sort-changed='sortChanged'
                >
                  <div class='text-center text-danger my-2'>
                    <b-spinner class='align-middle'></b-spinner>
                  </div>
                  <template v-slot:cell(instagram_page)='data'>
                    <button class='btn btn-sm ml-1 widget_btn' @click='viewPage(data.item)'>
                      <b-spinner v-if='gettingPage' small />
                      View Page
                    </button>
                  </template>
                  <template v-slot:cell(installation)='data'>
                    <button
                      class='btn btn-sm ml-1 widget_btn'
                      @click='addWidget(data.item.id)'
                    >
                      Add Widget
                    </button>
                  </template>

                  <template v-slot:cell(actions)='data'>
                    <!--                    <a :href="data.item.google_maps_url" class="mr-1" target="_blank"><img style="max-height: 30px" src="https://review-link.sfo3.cdn.digitaloceanspaces.com/Icon.png"></a>-->
                    <!--                    :to="{-->
                    <!--                    name: 'agency.customers.edit',-->
                    <!--                    params: { id: data.item.id },-->
                    <!--                    }"-->
                    <button
                      class='btn btn-sm ml-1 widget_btn'
                      @click='editWidget(data.item)'
                    >
                      <b-spinner v-if='gettingWidget' small />
                      Edit Widget
                    </button>

                    <b-button
                      variant='link'
                      title='Delete'
                      size='lg'
                      class='btn-delete'
                      :disabled='loadingRemove === data.item.id'
                      @click='remove(data.item)'
                    >
                      <b-spinner v-if='loadingRemove === data.item.id' small />
                      <i v-else class='uil uil-trash-alt'></i>
                    </b-button>
                  </template>
                </b-table>
              </div>
              <div class='row mt-4'>
                <div class='col'>
                  <div class='float-left mt-2'>
                    Total {{ totalWidgets }} Widgets
                  </div>
                  <div class='dataTables_paginate paging_simple_numbers float-right'>
                    <ul class='pagination pagination-rounded mb-0'>
                      <b-pagination
                        v-model='datatable.queries.page'
                        :total-rows='totalWidgets'
                        :per-page='datatable.queries.per_page'
                        @change='onPageChanged'
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
            <div v-else class='text-center'>
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model='showDeleteModal' title='Delete Widget'>
      <p v-if='selectedBusiness'
      >Are you sure you want to delete widget "{{
          selectedBusiness.widget_title
        }}"?</p
      >
      <template v-slot:modal-footer>
        <b-button variant='light' @click='showDeleteModal = false'
        >Cancel
        </b-button
        >
        <b-button variant='danger' @click='handleRemove'>Delete</b-button>
      </template>
    </b-modal>

    <b-modal v-model='showEditWidgetModal' :hide-footer='true' header-class='align-items-center'
             modal-class='edit_widget_modal widget-editor widget-modal' size='lg'
             title='Edit Instagram Widget'>
      <template v-slot:modal-header>
        <h5 class='text-white'>Edit Widget</h5>
        <div class='float-right'>
          <button class='btn btn-default btn-sm mr-2' @click='showEditWidgetModal = false'>
            Cancel
          </button>
          <button class='btn btn-success btn-sm mr-2' @click='saveEditor(false)'>
            <b-spinner v-if='savingEditor' style='
                    width: 1rem;
                    height: 1rem;
                '></b-spinner>
            Save
          </button>
          <button class='btn btn-success btn-sm' @click='saveEditor(true)'>
            <b-spinner v-if='savingEditor' style='
              width: 1rem;
              height: 1rem;
          '></b-spinner>
            Save & Exit
          </button>
        </div>
      </template>
      <div class='container-fluid'>
        <div class='row'>
          <div id='Navbar' class='sidebar'>
            <div class='left-nav-side float-left'>
              <button @click="editor.editing = 'source'">
                <i class='uil uil-search d-block'></i>
                Source
              </button>
              <button @click="editor.editing = 'layout'">
                <i class='uil uil-window-grid d-block'></i>
                Layout
              </button>
              <button @click="editor.editing = 'post_style'">
                <i class='uil uil-brush-alt d-block'></i>
                Post Style
              </button>
              <button @click="editor.editing = 'style'">
                <i class='uil uil-image-edit d-block'></i>
                Style
              </button>
            </div>
            <ul class='nav nav-sidebar'>
              <li v-if="editor.editing === 'source'" class='source'>
                <h4>Source</h4>
                <ul class='list-unstyled'>
                  <li class='d-flex'>
                    <input v-model='editor.source.username' type='text' class='source_input'>
                    <button class='source_button' @click='getWidget(true)'>
                      <i class='uil uil-search'></i>
                    </button>
                  </li>
                  <li class='widget_theme'>
                      <span class='layout-item d-flex justify-content-between'>
                            <span>Search Type</span>
                            <v-select
                              v-model='editor.source.type'
                              :clearable='false'
                              :options="[
                                  { label: 'Username', value: 'username' },
                                  { label: 'Hashtag', value: 'hashtag' },
                                ]"
                              :reduce='option => option.value'
                            />
                      </span>
                  </li>
                </ul>
              </li>
              <li v-if="editor.editing === 'layout'" class='layout'>
                <h4>Layout</h4>
                <ul class='list-unstyled'>
                  <li class='layouts_wrapper'>
                        <span v-for='layout in layouts' :key='layout.name' class='layout-item'>
                          <input :id='layout.name' :key='layout.name' type='radio' class='btn-check'
                                 autocomplete='off' />
                          <label :key="layout.name + 'label'" :for='layout.name' class='btn'
                                 @click='editor.layout.layoutStyle = layout.name'>
                            <img :alt='layout.label' :src="'/images/widget-layouts/' + layout.name + '-icon.png'" />
                            <span>{{ layout.label }}</span>
                          </label>
                        </span>
                  </li>
                  <li>
                    <span>Number of Columns</span>
                    <v-select
                      v-model='editor.layout.columns' :clearable='false' taggable
                      :options='[2,3,4]' />
                  </li>
                  <li>
                    <span>Number of Rows</span>
                    <v-select
                      v-model='editor.layout.rows' :clearable='false' taggable
                      :options='[1,2,3,4]' />
                  </li>
                  <li>
                    <span>Gap Between Posts</span>
                    <v-select
                      v-model='editor.layout.padding' :clearable='false' taggable
                      :options='[0,5,10,20,30]' />

                  </li>
                  <li>
                    <span>Profile Details</span>
                    <ToggleButton v-model='editor.layout.show_profile_details' :height='20' :width='45' color='#00b050'
                                  :sync='true'></ToggleButton>
                  </li>
                  <li v-if='editor.layout.show_profile_details'>
                    <ul class='list-unstyled profile_details'>
                      <li>
                        <span>Profile Picture</span>
                        <ToggleButton v-model='editor.layout.profile_details.profile_image' :height='20' :width='45'
                                      color='#00b050'
                                      :sync='true'></ToggleButton>
                      </li>
                      <li>
                        <span>Username</span>
                        <ToggleButton v-model='editor.layout.profile_details.username' :height='20' :width='45'
                                      color='#00b050'
                                      :sync='true'></ToggleButton>
                      </li>
                      <li>
                        <span>Follow Button</span>
                        <ToggleButton v-model='editor.layout.profile_details.follow_button' :height='20' :width='45'
                                      color='#00b050'
                                      :sync='true'></ToggleButton>
                      </li>
                      <li>
                        <span>Followers</span>
                        <ToggleButton v-model='editor.layout.profile_details.followers' :height='20' :width='45'
                                      color='#00b050'
                                      :sync='true'></ToggleButton>
                      </li>
                      <li>
                        <span>Following</span>
                        <ToggleButton v-model='editor.layout.profile_details.following' :height='20' :width='45'
                                      color='#00b050'
                                      :sync='true'></ToggleButton>
                      </li>
                      <li>
                        <span>Posts</span>
                        <ToggleButton v-model='editor.layout.profile_details.post_count' :height='20' :width='45'
                                      color='#00b050'
                                      :sync='true'></ToggleButton>
                      </li>
                      <li>
                        <span>Name</span>
                        <ToggleButton v-model='editor.layout.profile_details.name' :height='20' :width='45'
                                      color='#00b050'
                                      :sync='true'></ToggleButton>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li v-if="editor.editing === 'post_style'" class='layout'>
                <h4>Post Style</h4>
                <ul class='list-unstyled'>
                  <li class='post_style_wrapper'>
                      <span class='layout-item d-flex justify-content-between'>
                            <span>Post Style</span>
                            <v-select
                              v-model='editor.post_style.post_style'
                              :clearable='false'
                              :options="[
                                  { label: 'Expanded', value: 'expanded' },
                                  { label: 'Image Card', value: 'image_card' },
                                ]"
                              :reduce='option => option.value'
                            />
                      </span>
                  </li>
                  <li v-if="editor.post_style.post_style === 'expanded'" class='post_details'>
                    <ul class='list-unstyled profile_details'>
                      <li>
                        <span>Profile Picture</span>
                        <ToggleButton v-model='editor.post_style.post_details.profile_image' :height='20' :width='45'
                                      color='#00b050'
                                      :sync='true'></ToggleButton>
                      </li>
                      <li>
                        <span>Username</span>
                        <ToggleButton v-model='editor.post_style.post_details.username' :height='20' :width='45'
                                      color='#00b050'
                                      :sync='true'></ToggleButton>
                      </li>
                      <li>
                        <span>Likes Count</span>
                        <ToggleButton v-model='editor.post_style.post_details.likes_count' :height='20' :width='45'
                                      color='#00b050'
                                      :sync='true'></ToggleButton>
                      </li>
                      <li>
                        <span>Comments Count</span>
                        <ToggleButton v-model='editor.post_style.post_details.comments_count' :height='20' :width='45'
                                      color='#00b050'
                                      :sync='true'></ToggleButton>
                      </li>
                      <li>
                        <span>Caption</span>
                        <ToggleButton v-model='editor.post_style.post_details.caption' :height='20' :width='45'
                                      color='#00b050'
                                      :sync='true'></ToggleButton>
                      </li>
                    </ul>
                  </li>
                  <li class='post_action_wrapper'>
                      <span class='layout-item d-flex justify-content-between'>
                            <span>Post Action</span>
                            <v-select v-model='editor.post_style.post_action'
                                      :clearable='false'
                                      :options="[
                                  { label: 'Open in Modal', value: 'modal' },
                                  { label: 'Open on Instagram', value: 'instagram' },
                                ]"
                                      :reduce='option => option.value'
                            />
                      </span>
                  </li>
                </ul>
              </li>
              <li v-if="editor.editing === 'style'" class='layout'>
                <h4>Widget Style</h4>
                <ul class='list-unstyled'>
                  <li class='widget_theme'>
                      <span class='layout-item d-flex justify-content-between'>
                            <span>Widget Theme</span>
                            <v-select
                              v-model='editor.styles.theme'
                              :clearable='false'
                              :options="[
                                  { label: 'Light', value: 'light' },
                                  { label: 'Dark', value: 'dark' },
                                ]"
                              :reduce='option => option.value'
                            />
                      </span>
                  </li>
                  <li class='header_styles'>
                    <span>Header Background</span>
                    <PopperColor v-model='editor.styles.header_bg'
                                 title='Header Background' />
                  </li>
                  <li class='header_styles'>
                    <span>Header Text Color</span>
                    <PopperColor v-model='editor.styles.header_text'
                                 title='Header Text Color' />
                  </li>
                  <li class='header_styles'>
                    <span>Follow Button Background</span>
                    <PopperColor v-model='editor.styles.follow_button_bg'
                                 title='Follow Button Background' />
                  </li>
                  <li class='header_styles'>
                    <span>Follow Button Text Color</span>
                    <PopperColor v-model='editor.styles.follow_button_text'
                                 title='Follow Button Text Color' />
                  </li>
                  <li class='header_styles'>
                    <span>Load More Button Background</span>
                    <PopperColor v-model='editor.styles.load_more_button_bg'
                                 title='Load More Button Background' />
                  </li>
                  <li class='header_styles'>
                    <span>Load More Button Text Color</span>
                    <PopperColor v-model='editor.styles.load_more_button_text'
                                 title='Load More Button Text Color' />
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class='editor-content instagram-widget ' :class="{ 'dark': editor.styles.theme === 'dark' }">
            <div v-if="profileFetchState !== ''">
              <div v-if="profileFetchState === 'fetching' || profileFetchState === 'error'" class='fetch_loader'>
                <b-spinner v-if="profileFetchState === 'fetching'"></b-spinner>
                <h3 v-if="profileFetchState === 'error'" class='error_fetching'>{{ profileFetchMessage }}</h3>
              </div>
              <div v-if="profileFetchState === '' || profileFetchState === 'success'">
                <div v-if="postsType === 'profile'">
                  <div v-if='editor.layout.show_profile_details'>
                    <div :style='{ backgroundColor: editor.styles.header_bg, color: editor.styles.header_text }'
                         class="profile_details_container">
                      <div class='profile_details_left'>
                        <div v-if='editor.layout.profile_details.profile_image && profile && profile.profile_picture_url'
                             class='profile_picture_container rounded rounded-circle'>
                          <img
                            :src="(profile.profile_picture_url) ? profile.profile_picture_url : '/images/widget-layouts/profile.jpg'"
                            class='profile_picture'>
                        </div>
                        <div class='profile_names_container'>
                          <div class='username_container'>
                            <h3 v-if='editor.layout.profile_details.name' class='name'
                                :style='{ color: editor.styles.header_text }'>{{ profile.name }}</h3>
                          </div>
                          <div class='username_container'>
                            <h3 v-if='editor.layout.profile_details.username' class='username'
                                :style='{ color: editor.styles.header_text }'>
                              {{ profile.username }}
                            </h3>
                          </div>

                        </div>
                      </div>
                      <div class='profile_insights_container'>
                        <p v-if='editor.layout.profile_details.post_count' class='insight_count_tag'
                           :style='{ color: editor.styles.header_text }'>
                          <strong class='post_count'
                                  :style='{ color: editor.styles.header_text }'>{{ formatNumber(profile.media_count)
                            }}</strong>
                          posts
                        </p>
                        <p v-if='editor.layout.profile_details.followers' class='insight_count_tag'
                           :style='{ color: editor.styles.header_text }'>
                          <strong class='followers_count'
                                  :style='{ color: editor.styles.header_text }'>{{ formatNumber(profile.followers_count)
                            }}</strong>
                          followers
                        </p>
                        <p v-if='editor.layout.profile_details.following' class='insight_count_tag'
                           :style='{ color: editor.styles.header_text }'>
                          <strong class='following_count'
                                  :style='{ color: editor.styles.header_text }'>{{ formatNumber(profile.follows_count)
                            }}</strong>
                          following
                        </p>
                      </div>
                      <div class='follow_button_container'>
                        <button v-if='editor.layout.profile_details.follow_button' class='follow_button'
                                :style='{ backgroundColor: editor.styles.follow_button_bg, color: editor.styles.follow_button_text }'
                                @click='redirectToProfile'
                        >
                          <i class='uil uil-instagram'></i> Follow
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="editor.layout.layoutStyle === 'grid'" class='posts_container grid_layout_posts_container'
                     :style="{
                gridTemplateColumns: `repeat(${editor.layout.columns}, 1fr)`,
                gap: editor.layout.padding + 'px',
              }">
                  <div v-for='post in imageCardPosts'
                       :key='post.id'
                       class='post image_card' @click='post_action(post)'>
                    <div class='media_wrapper'>
                      <i class='media_icon'
                         :class="{ 'uil uil-play': post.media_type === 'VIDEO', 'uil uil-layers': post.media_type === 'CAROUSEL_ALBUM' }"></i>
                      <img v-if="post.media_type === 'IMAGE' && post.media_product_type === 'FEED'"
                           :src='post.media_url'
                           class='post_image' alt='Post image'>
                      <img v-else-if="post.media_type === 'IMAGE'" :src='post.media_url' class='post_image'
                           alt='Post image'>
                      <img v-else-if="post.media_type === 'CAROUSEL_ALBUM'" :src='post.media_url' class='post_image'
                           alt='Post image'>

                      <video v-if="post.media_type === 'VIDEO' && post.media_product_type === 'REELS'"
                             :src='post.media_url' class='post_image'></video>
                      <video v-else-if="post.media_type === 'VIDEO'" :src='post.media_url' class='post_image'></video>
                    </div>
                    <div class='post_insights_container'>
                          <span class='d-flex gap-5'>
                            <p class='post_like'>
                              <i class='uil uil-heart-alt'></i>{{ formatNumber(post.like_count) }}
                            </p>
                            <p class='post_like ml-3'>
                              <i class='uil uil-comment'></i>{{ formatNumber(post.comments_count) }}
                            </p>
                          </span>
                    </div>
                  </div>
                  <div v-for='post in expandedPosts'
                       :key='post.id'
                       class='post expanded'>
                    <div class='post_header justify-content-between align-items-center'>
                      <div class='d-flex justify-content-start align-items-center'>
                        <div v-if='editor.post_style.post_details.profile_image && profile && profile.profile_picture_url' class='profile_image_container'>
                          <img
                            :src="(profile.profile_picture_url) ? profile.profile_picture_url : '/images/widget-layouts/profile.jpg'"
                            :alt='profile.name' class='profile_image'>
                        </div>
                        <div v-if='editor.post_style.post_details.username' class='post_username_container mx-1'>
                          <h3 class='username'>{{ profile.username }}</h3>
                        </div>
                      </div>
                      <div class='font-size-24 mx-2'>
                        <a :href="'https://instagram.com/' + profile.username" target='_blank'>
                          <i class='uil uil-instagram instagram_icon'></i>
                        </a>
                      </div>
                    </div>
                    <div class='post_image_container' @click='post_action(post)'>
                      <div class='media_wrapper'>
                        <i class='media_icon'
                           :class="{ 'uil uil-play': post.media_type === 'VIDEO', 'uil uil-layers': post.media_type === 'CAROUSEL_ALBUM' }"></i>
                        <img v-if="post.media_type === 'IMAGE' && post.media_product_type === 'FEED'"
                             :src='post.media_url'
                             class='post_image' alt='Post image'>
                        <img v-else-if="post.media_type === 'IMAGE'" :src='post.media_url' class='post_image'
                             alt='Post image'>
                        <img v-else-if="post.media_type === 'CAROUSEL_ALBUM'" :src='post.media_url' class='post_image'
                             alt='Post image'>

                        <video v-if="post.media_type === 'VIDEO' && post.media_product_type === 'REELS'"
                               :src='post.media_url' class='post_image'></video>
                        <video v-else-if="post.media_type === 'VIDEO'" :src='post.media_url' class='post_image'></video>
                      </div>
                    </div>
                    <div class='post_footer' @click='post_action(post)'>
                      <div class='post_insights_container'>
                    <span class='d-flex gap-5'>
                      <p v-if='editor.post_style.post_details.likes_count' class='post_like'>
                        <i class='uil uil-heart-alt'></i>{{ formatNumber(post.like_count) }}
                      </p>
                      <p v-if='editor.post_style.post_details.comments_count' class='post_like ml-2'>
                        <i class='uil uil-comment'></i>{{ formatNumber(post.comments_count) }}
                      </p>
                    </span>
                      </div>
                      <div v-if='editor.post_style.post_details.caption' class='post_caption_container'>
                        <p class='caption'>{{ post.caption }}</p>
                      </div>
                    </div>
                  </div>

                </div>
                <div v-if="showLoadMore && editor.layout.layoutStyle === 'grid'" class='load_more_container'>
                  <button class='load_more_button'
                          :style='{ backgroundColor: editor.styles.load_more_button_bg, color: editor.styles.load_more_button_text }'
                          @click='loadMore'
                  >Load More
                  </button>
                </div>
                <div v-if="editor.layout.layoutStyle === 'slider'"
                     class='posts_container slider_layout_posts_container'>
                  <Swiper
                    :slides-per-view='editor.layout.columns'
                    :space-between='editor.layout.padding'
                    :modules='modules'
                    navigation
                    :loop='true'
                    class='mySwiper'
                    :clickable='true'
                    @swiper='onSwiper'
                    @slideChange='onSlideChange'
                  >
                    <SwiperSlide v-for='post in posts' :key='post.id'>
                      <div v-if="editor.post_style.post_style === 'image_card' && post.media_url"
                           class='post image_card'
                           @click='post_action(post)'>
                        <div class='media_wrapper'>
                          <i class='media_icon'
                             :class="{ 'uil uil-play': post.media_type === 'VIDEO', 'uil uil-layers': post.media_type === 'CAROUSEL_ALBUM' }"></i>
                          <img v-if="post.media_type === 'IMAGE' && post.media_product_type === 'FEED'"
                               :src='post.media_url'
                               class='post_image' alt='Post image'>
                          <img v-else-if="post.media_type === 'IMAGE'" :src='post.media_url' class='post_image'
                               alt='Post image'>
                          <img v-else-if="post.media_type === 'CAROUSEL_ALBUM'" :src='post.media_url' class='post_image'
                               alt='Post image'>

                          <video v-if="post.media_type === 'VIDEO' && post.media_product_type === 'REELS'"
                                 :src='post.media_url' class='post_image'></video>
                          <video v-else-if="post.media_type === 'VIDEO'" :src='post.media_url'
                                 class='post_image'></video>
                        </div>
                        <div class='post_insights_container'>
                          <p class='post_like'>
                            <i class='uil uil-heart-alt'></i> {{ formatNumber(post.like_count) }}
                          </p>
                        </div>
                      </div>
                      <div v-if="editor.post_style.post_style === 'expanded' && post.media_url" class='post expanded'
                           @click='post_action(post)'>
                        <div class='post_header'>
                          <div class='profile_image_container'>
                            <img
                              v-if="profile && profile.profile_picture_url && editor.post_style.post_details.profile_image"
                              :src="(profile.profile_picture_url) ? profile.profile_picture_url : '/images/widget-layouts/profile.jpg'"
                              :alt='profile.name' class='profile_image'>
                          </div>
                          <div v-if="editor.post_style.post_details.username" class='post_username_container'>
                            <h3 class='username'>{{ profile.username }}</h3>
                          </div>
                        </div>
                        <div class='post_image_container'>
                          <div class='media_wrapper'>
                            <i class='media_icon'
                               :class="{ 'uil uil-play': post.media_type === 'VIDEO', 'uil uil-layers': post.media_type === 'CAROUSEL_ALBUM' }"></i>
                            <img v-if="post.media_type === 'IMAGE' && post.media_product_type === 'FEED'"
                                 :src='post.media_url'
                                 class='post_image' alt='Post image'>
                            <img v-else-if="post.media_type === 'IMAGE'" :src='post.media_url' class='post_image'
                                 alt='Post image'>
                            <img v-else-if="post.media_type === 'CAROUSEL_ALBUM'" :src='post.media_url'
                                 class='post_image'
                                 alt='Post image'>

                            <video v-if="post.media_type === 'VIDEO' && post.media_product_type === 'REELS'"
                                   :src='post.media_url' class='post_image'></video>
                            <video v-else-if="post.media_type === 'VIDEO'" :src='post.media_url'
                                   class='post_image'></video>
                          </div>
                        </div>
                        <div class='post_footer'>
                          <div class='post_insights_container'>
                    <span class='d-flex gap-5'>
                      <p v-if="editor.post_style.post_details.likes_count" class='post_like'>
                        <i class='uil uil-heart-alt'></i>{{ formatNumber(formatNumber(post.like_count)) }}
                      </p>
                      <p v-if="editor.post_style.post_details.comments_count" class='post_like ml-2'>
                        <i class='uil uil-comment'></i>{{ formatNumber(post.comments_count) }}
                      </p>
                    </span>
                          </div>
                          <div v-if="editor.post_style.post_details.caption" class='post_caption_container'>
                            <p class='caption'>{{ post.caption }}</p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>

                <b-modal v-model='showPostModal' :hide-footer='true' title='Posts'
                         :header-class="{ 'dark': editor.styles.theme === 'dark' }" size='lG'>
                  <div :class="{ 'dark': editor.styles.theme === 'dark' }" class='modal_posts_container'>
                    <div class='post expanded'>
                      <div class='post_header d-flex justify-content-between'>
                        <div class='post_header_left d-flex align-items-center'>
                          <div class='profile_image_container'>

                            <img
                              v-if="profile && profile.profile_picture_url"
                              :src="(profile.profile_picture_url) ? profile.profile_picture_url : '/images/widget-layouts/profile.jpg'"
                              :alt='profile.name' class='profile_image' @click='redirectToProfile'>
                          </div>
                          <div class='post_username_container ml-2'>
                            <h3 class='username' @click='redirectToProfile'>{{ profile.username }}</h3>
                          </div>
                        </div>
                        <button class='follow_btn_post' @click='redirectToProfile'>Follow</button>
                      </div>
                      <div class='post_image_container'>
                        <div class='media_wrapper'>
                          <i class='media_icon'
                             :class="{ 'uil uil-play': modalPost.media_type === 'VIDEO', 'uil uil-layers': modalPost.media_type === 'CAROUSEL_ALBUM' }"></i>
                          <img v-if="modalPost.media_type === 'IMAGE' && modalPost.media_product_type === 'FEED'"
                               :src='modalPost.media_url'
                               class='post_image' alt='Post image'>
                          <img v-else-if="modalPost.media_type === 'IMAGE'" :src='modalPost.media_url'
                               class='post_image'
                               alt='Post image'>
                          <img v-else-if="modalPost.media_type === 'CAROUSEL_ALBUM'" :src='modalPost.media_url'
                               class='post_image'
                               alt='Post image'>

                          <video v-if="modalPost.media_type === 'VIDEO' && modalPost.media_product_type === 'REELS'"
                                 :src='modalPost.media_url' class='post_image' autoplay></video>
                          <video v-else-if="modalPost.media_type === 'VIDEO'" :src='modalPost.media_url'
                                 class='post_image' autoplay></video>
                        </div>
                      </div>
                      <div class='post_footer'>
                        <div class='post_insights_container'>
                    <span class='d-flex gap-5'>
                      <p class='post_like'>
                        <strong>{{ formatNumber(modalPost.like_count) }} Likes</strong>
                      </p>
                    </span>
                        </div>
                        <div class='post_caption_container'>
                          <p class='caption'><strong>{{ profile.username }}</strong> {{ modalPost.caption }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-modal>

              </div>
            </div>

            <b-modal v-model='showPostModal' :hide-footer='true' title='Posts'
                     :header-class="{ 'dark': editor.styles.theme === 'dark' }" size='lG'>
              <div v-for='post in posts' :key='post.id' :class="{ 'dark': editor.styles.theme === 'dark' }"
                   class='modal_posts_container'>
                <div class='post expanded'>
                  <div class='post_header d-flex justify-content-between'>
                    <div class='post_header_left d-flex align-items-center'>
                      <div class='profile_image_container'>
                        <img
                          v-if="profile && profile.profile_picture_url"
                          :src="(profile.profile_picture_url) ? profile.profile_picture_url : '/images/widget-layouts/profile.jpg'"
                          :alt='profile.name' class='profile_image' @click='redirectToProfile'>
                      </div>
                      <div class='post_username_container ml-2'>
                        <h3 class='username' @click='redirectToProfile'>{{ profile.username }}</h3>
                      </div>
                    </div>
                    <button class='follow_btn_post' @click='redirectToProfile'>Follow</button>
                  </div>
                  <div class='post_image_container' @click='post_action(post)'>
                    <div class='media_wrapper'>
                      <i class='media_icon'
                         :class="{ 'uil uil-play': post.media_type === 'VIDEO', 'uil uil-layers': post.media_type === 'CAROUSEL_ALBUM' }"></i>
                      <img v-if="post.media_type === 'IMAGE' && post.media_product_type === 'FEED'"
                           :src='post.media_url'
                           class='post_image' alt='Post image'>
                      <img v-else-if="post.media_type === 'IMAGE'" :src='post.media_url' class='post_image'
                           alt='Post image'>
                      <img v-else-if="post.media_type === 'CAROUSEL_ALBUM'" :src='post.media_url' class='post_image'
                           alt='Post image'>

                      <video v-if="post.media_type === 'VIDEO' && post.media_product_type === 'REELS'"
                             :src='post.media_url' class='post_image'></video>
                      <video v-else-if="post.media_type === 'VIDEO'" :src='post.media_url' class='post_image'></video>
                    </div>
                    <div class='post_insights_container'>
                    <span class='d-flex gap-5'>
                      <p class='post_like'>
                        <strong>{{ formatNumber(modalPost.like_count) }} Likes</strong>
                      </p>
                    </span>
                    </div>
                    <div class='post_caption_container'>
                      <p class='caption'><strong>{{ profile.username }}</strong> {{ post.caption }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </b-modal>

          </div>
        </div>
      </div>
    </b-modal>

    <b-modal v-model='showCreateWidgetModal' title='Create Widget' :hide-footer='true'>
      <span for=''>Widget Title</span>
      <TextInput v-model='newWidgetTitle' class='d-block mt-2' />
      <button
        class='ml-1 mt-3 btn btn-dark-blue btn-sm'
        @click='createWidget'
      >
        <i class='uil uil-plus'></i>
        Create Widget
      </button>
    </b-modal>

    <b-modal v-model='showAddWidgetModal' title='Add Widget' size='lg' :hide-footer='true'>
      <!-- eslint-disable-next-line -->
        <textarea cols='30' rows='5' class='form-control' style='white-space: pre-line' v-html='embedHTML'>
        </textarea>
      <b-button class='mt-5' @click='copyToClipboard'>Copy Widget</b-button>
    </b-modal>
  </div>
</template>

<!--WIdget Styles-->
<style lang="scss">

.fetch_loader {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.customer_select {
    border: 1px solid #8a8a8a;
    border-radius: 4px;
    color: #000;

    * {
        color: #000;
    }
}

.source_input {
    width: 100%;
    padding: .5rem;
    border-radius: 4px;
    border: 2px solid #456db9;
    font-size: 1.1rem;
}

.source_button {
    height: 100%;
    background: #284273;
    color: #fff;
    border: none;
    padding: .5rem;
    font-size: 1.1rem;
}

.v-select {
    cursor: pointer !important;

    * {
        cursor: pointer !important;
    }
}

.layouts_wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.layout-item {

    .btn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: fit-content;

        img {
            display: inline-block;
            width: 5rem;
        }

        label {
            display: inline-block;
            font-size: 12px;
        }
    }

}

.instagram-widget {
    max-height: 93vh;
    overflow-y: scroll;

    .profile_details_container {
        padding: 2rem 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3rem;

        .profile_details_left {
            display: flex;
            align-items: center;
            gap: 1rem;
        }

        .profile_details_left {
            display: flex;
            align-items: center;
            gap: 1rem;
        }

        .profile_picture_container {
            width: 80px;
            height: 80px;
            border-radius: 50%;
        }

        .profile_picture {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
        }

        .profile_names_container {
            justify-self: flex-start;

            .name {
                margin-bottom: .2rem;
                color: #222
            }

            .username {
                font-size: 16px;
                font-weight: 400;
                margin-block: 0;
                color: #555;
            }

            .username::before {
                content: '@';
            }
        }


        .follow_button {
            background: #007FFF;
            color: #fff;
            padding: .5rem 1rem;
            border-radius: 4px;
            border: none;
        }

        .profile_insights_container {
            display: flex;
            justify-content: center;
            align-items: center;

            .insight_count_tag {
                margin-inline: .5rem;
                font-size: 18px;
                margin-bottom: 0;
                color: #555;
                line-height: 1;
                text-transform: capitalize;
                strong {
                    display: block;
                    text-align: center;
                    color: #222;
                    font-size: 22px;
                    margin-bottom: .2rem;
                }
            }
        }

    }

    .posts_container {
        padding: 1rem 2rem;
    }

    .grid_layout_posts_container {
        display: grid;
    }

    .post {
        cursor: pointer;
    }

    .media_wrapper {
        width: 100%;
        height: 100%;
        aspect-ratio: 1;
        position: relative;
        background: #0a0a0a;
        overflow: hidden;

        .media_icon {
            position: absolute;
            right: 1rem;
            top: 1rem;
            color: #fff;
            font-size: 2rem;
            font-weight: 700;
        }
    }

    .post.image_card:hover .post_insights_container {
        display: grid;
        place-items: center;
    }

    .post.image_card {
        width: 100%;
        position: relative;

        img.post_image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        video.post_image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .post_insights_container {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.5);
            display: none;

            .post_like {
                font-size: 1.5rem;
                color: #fff
            }
        }
    }

    .post.expanded {
        width: 100%;
        overflow: hidden;

        .post_header {
            display: flex;
            gap: .3rem;
            margin-bottom: .5rem;

            .profile_image {
                width: 50px;
                border-radius: 50%;
            }

            .username {
                font-size: .9rem;
                font-weight: 700;
                color: #111;
            }
        }

        .post_image_container {
            width: 100%;

            .media_wrapper {
                width: 100%;
                height: 100%;
                aspect-ratio: 1;
                position: relative;
                overflow: hidden;

                img.post_image {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                video.post_image {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .post_footer {

            .post_insights_container {
                p {
                    margin-block: 0.5rem;
                    font-size: 1.2rem;
                    color: #111;
                }
            }

            .post_caption_container {
                .caption {
                    color: #111;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    line-clamp: 3;
                    -webkit-box-orient: vertical;
                }
            }

        }

    }

    .load_more_container {
        width: 100%;
        margin-top: 2rem;
        margin-bottom: 2rem;
        text-align: center;

        .load_more_button {
            background: #007FFF;
            color: #fff;
            padding: .5rem 1rem;
            border-radius: 4px;
            border: none;
            margin-inline: auto;
            margin-block: .5rem;
            font-size: 1.1rem;

        }
    }

}

.modal-body {
    .media_wrapper {
        width: 100%;
        height: 100%;
        aspect-ratio: 1;
        position: relative;
        overflow: hidden;

        .media_icon {
            position: absolute;
            right: 1rem;
            top: 1rem;
            color: #fff;
            font-size: 2rem;
            font-weight: 700;
        }

        img.post_image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        video.post_image {
            width: 100%;
            height: 100%;
        }
    }
}

.modal_posts_container .post.expanded {
    width: 100%;
    overflow: hidden;
    border-bottom: 1px solid #cecece;

    .post_header {
        display: flex;
        gap: .3rem;
        padding-block: .5rem;
        cursor: pointer;

        .profile_image {
            width: 40px;
            border-radius: 50%;
        }

        .username {
            font-size: .9rem;
            font-weight: 700;
            color: #111;
        }

        .follow_btn_post {
            border: none;
            background: transparent;
            color: #0e8dc7;
            font-size: .9rem;
        }
    }

    .post_image_container {
        width: 100%;

        img.post_image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        video.post_image {
            width: 100%;
            height: 100%;
        }
    }

    .media_wrapper {
        width: 100%;
        height: 100%;
        aspect-ratio: 1;
        position: relative;

        .media_icon {
            position: absolute;
            right: 1rem;
            top: 1rem;
            color: #fff;
            font-size: 2rem;
            font-weight: 700;
        }
    }

    .media_wrapper {
        width: 100%;
        height: 100%;
        aspect-ratio: 1;
        position: relative;

        .media_icon {
            position: absolute;
            right: 1rem;
            top: 1rem;
            color: #fff;
            font-size: 2rem;
            font-weight: 700;
        }
    }

    .post_footer {

        .post_insights_container {
            p {
                margin-block: 0.5rem;
                font-size: 1.2rem;
                color: #111;
            }
        }

        .post_caption_container {
            .caption {
                color: #111;
            }
        }

    }
}

li:has( .profile_details) {
    justify-content: flex-start !important;

    .profile_details {
        width: 100%;
    }

    li {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: .5rem;
    }
}

.instagram-widget.dark {
    background: #1b1b1b !important;

    * {
        color: #fff !important;
    }

    .post {
        border: 1px solid #1f1d10;
    }

    .post * {
        color: #fff !important;
    }

    .media_wrapper {
        background: #0a0a0a;
    }

    .media_icon {
        color: #fff;
    }
}


.modal-header.dark {
    background: #1b1b1b !important;

    * {
        color: #fff !important
    }
}

.modal-body:has(.dark) {
    background: #1b1b1b !important;
    color: #fff !important;

    .dark * {
        color: #fff !important
    }
}
</style>
<style>
.edit_widget_modal .modal-dialog {
    margin: 0;

    .modal-content {
        width: 100vw;
        height: 100vh;
        border-radius: 0
    }
}

.widget_btn {
    background: #f2f4f3;
    border: 1px solid #474747;
    color: #213556;
}
</style>
<style lang='scss' scoped>
.status-filter {
  text-transform: capitalize;
}
</style>
<style lang='scss'>
iframe#preview-frame {
  width: 100%;
  height: calc(100% - 5px);
}

:root {
  --vs-search-input-color: #000;
  --vs-selected-color: #fff;
  --vs-colors--dark: #000;
  --vs-search-input-bg: transparent;
  --vs-border-width: 0;
  --vs-controls-color: #fff;
}

.v-select {
  &.rotator-delay span.vs__selected:after {
    content: 's';
  }

  &.min-rating span.vs__selected:after {
    content: 'Stars';
    padding-left: 2px;
  }
}

.vs__search[readonly] {
  display: none;
}

.v-select:not(.vs--open) {
  .vs__search {
    display: none;
  }

}

span.vs__selected {
  right: 0;
}

.vs--unsearchable {
  &.vs--loading .vs__selected, &.vs--open .vs__selected {
    position: relative !important;
  }
}

div#image_overlay_panel {
  z-index: 100000000 !important;
}

.tooltip-inner {
  max-width: 400px;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.widget-modal {
  .modal-body {
    padding: 20px;
  }
}

.widget-creator {
  margin: 0;
  padding-top: 0;
  padding-bottom: 10px;

  h3 {
    font-size: 16px;
  }

  input, button, .btn {
    font-size: 14px;
  }
}

.pac-container {
  z-index: 100000;
}

.widget-editor {
  padding: 0 !important;

  .modal-dialog {
    width: 100%;
    height: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0;
  }

  .modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
    border: 0;

    .modal-header {
      background: #4f688f;
      color: #fff;
      padding: 5px 15px;
      border-radius: 0;
      border: 0;
    }

    .modal-body {
      padding: 0;
    }
  }

  .left-nav-side {
    width: 50px;
    font-size: 10px;
    margin-right: 10px;
    text-align: center;

    button {
      margin-bottom: 15px;
      border: 0;
      appearance: none;
      background: none;
      color: #bfbfbf;

      i {
        font-size: 25px;
        line-height: 25px;
      }

      &.active, &:hover {
        color: #fff;
      }
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .nav-sidebar {
    width: calc(100% - 60px);

    > li {
      list-style: none;
      width: 100%;

      > h4 {
        margin-top: 0;
        color: #fff;
      }

      > ul {
        > li {
          background: #51688f;
          border-radius: 8px;
          padding: 5px 10px;
          margin-bottom: 10px;
          width: 100%;
          clear: both;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          > span {
            flex: 1;
            flex-wrap: nowrap;
          }

          .vue-js-switch {
            margin-bottom: 0;
          }

          /*.vue-js-switch{
            display: inline-flex;
            flex: 1;
            justify-content: flex-end;
            margin-bottom: 0;
          }
          .v-select{
            display: inline-flex;
            flex: 1;
            justify-content: flex-end;
            span.vs__selected{
              position: relative !important;
              + input{
                position: absolute;
                clip: rect(0, 0, 0, 0);
              }
            }
          }*/
        }
      }
    }

    .style-selector {
      label {
        min-height: 120px;

        &:hover {
          color: #fff;
        }

        width: 50%;
        border: 4px solid #51688f;

        &.checked {
          border: 4px solid #00b050;
        }

        img {
          max-width: 100%;
          height: auto;

        }

        span {

        }
      }
    }

    .radius-selector {
      label.btn {
        padding: 3px;
        margin-left: 7px;
        margin-bottom: 0;
        background: #5f7cab;

        &.checked, &:hover {
          background: #00b050;
        }

        img {
          max-height: 18px;
        }
      }
    }

    .v-select ul[role="listbox"] {
      z-index: 1000;
      color: #000;
    }

    /*    .v-select:not(.vs--open){
          .vs__search{
            display: none;
          }
        }*/

  }

  .sidebar {
    background-color: #284373;
    color: #fff;
    padding-right: 20px;
    padding-top: 20px;
    min-height: calc(100vh - 52px);
    max-width: 360px;
    width: 100%;
  }

  .editor-content {
    flex: 1;
  }

  --track-width: 45px !important;
  --track-height: 25px !important;
  --track-active-color: #2196F3;
}
</style>

<script>
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/swiper-vue.mjs'
import 'swiper/swiper-bundle.css'
import { ToggleButton } from 'vue-js-toggle-button'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import PopperColor from '@components/Common/PopperColor'
import Vue from 'vue'

export default {
  name: 'InstaWidget',
  components: {
    Swiper,
    SwiperSlide,
    ToggleButton,
    'v-select': vSelect,
    PopperColor,
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper)
    }
    const onSlideChange = () => {
      console.log('slide change')
    }
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    }
  },
  data() {
    return {
      layouts: [
        {
          label: 'Grid',
          name: 'grid',
        },
        {
          label: 'Slider',
          name: 'slider',
        },
      ],
      profileFetchState: '',
      profileFetchMessage: '',
      selectedId: 0,
      datatable: {
        items: [],
        totalItems: 1,
        columns: [
          { key: 'id', label: '#', sortable: true },
          { key: 'widget_title', label: 'Widget', sortable: true },
          { key: 'instagram_page', label: 'Instagram Page', class: 'instagram_page' },
          { key: 'installation', label: 'Installation', class: 'installation' },
          { key: 'actions', label: 'Actions', class: 'actions' },
        ],
        queries: {
          per_page: 10,
          page: 1,
          status: 'active',
          prospect_status: 'all',
          search: null,
          sort_by: 'id',
          sort_desc: true,
        },
      },
      profile: {
        username: '',
        name: '',
        profile_picture_url: '',
        followers_count: '',
        follows_count: '',
        media_count: '',
      },
      tokens: {
        accessToken: '',
        igID: '',
      },
      posts: [{
        'id': '',
        'like_count': '',
        'comments_count': '',
        'caption': '',
        'media_url': '',
      }],
      postsType: 'profile',
      widgetId: 0,
      newWidgetTitle: '',
      editor: {
        source: {
          username: '',
          type: 'username',
        },
        layout: {
          layoutStyle: 'grid',
          rows: 3,
          columns: 3,
          show_profile_details: true,
          profile_details: {
            profile_image: true,
            username: true,
            name: true,
            follow_button: true,
            post_count: true,
            followers: true,
            following: true,
          },
          padding: 5,
        },
        post_style: {
          post_style: 'image_card',
          post_details: {
            profile_image: true,
            username: true,
            likes_count: true,
            comment_count: true,
            caption: true,
          },
          post_action: 'instagram',
        },
        editing: 'source',
        styles: {
          theme: 'light',
          header_bg: '#ffffff',
          header_text: '#000000',
          follow_button_bg: '#0081ff',
          follow_button_text: '#ffffff',
          load_more_button_bg: '#0081ff',
          load_more_button_text: '#ffffff',
        },
      },
      default_editor: {
        source: {
          username: 'k.mbappe',
          type: 'username',
        },
        layout: {
          layoutStyle: 'grid',
          rows: 3,
          columns: 3,
          show_profile_details: true,
          profile_details: {
            profile_image: true,
            username: true,
            name: true,
            follow_button: true,
            post_count: true,
            followers: true,
            following: true,
          },
          padding: 5,
        },
        post_style: {
          post_style: 'image_card',
          post_details: {
            profile_image: true,
            username: true,
            likes_count: true,
            comment_count: true,
            caption: true,
          },
          post_action: 'instagram',
        },
        editing: 'layout',
        styles: {
          theme: 'light',
          header_bg: '#ffffff',
          header_text: '#000000',
          follow_button_bg: '#0081ff',
          follow_button_text: '#ffffff',
          load_more_button_bg: '#0081ff',
          load_more_button_text: '#ffffff',
        },
      },
      modalPost: {
        'id': '',
        'like_count': '',
        'comments_count': '',
        'caption': '',
        'media_url': '',
      },
      savingEditor: false,
      showCreateWidgetModal: false,
      showDeleteModal: false,
      selectedBusiness: null,
      loadingBusinesses: false,
      loadingWidgets: false,
      loadingImpersonate: false,
      loadingRemove: null,
      showWelcomeModal: false,
      createIsBusy: false,
      widgetDefaultText: '',
      showCreateLeadModal: false,
      showShippingModal: false,
      showEditWidgetModal: false,
      showPostModal: false,
      showAddWidgetModal: false,
      gettingWidget: false,
      gettingPage: false,
      selectedCustomer: null,
      customerOptions: [],
      shippingModalOwner: {
        first_name: '',
        last_name: '',
        id: null,
        tracking_number: '',
        working: false,
        tracking_numbers: [],
      },
      additionalPostsToShow: 0,
      widgets: [],
      selectedWidget: null,
    }
  },

  computed: {
    embedHTML() {
      return `<!-- Start Instagram Widget -->
        &lt;script src="https://rengine.sfo3.cdn.digitaloceanspaces.com/generator-scripts/v3/instastream-widget-generator.js">&lt;/script>
            <instagram-widget widget-id='${this.selectedWidget}'></instagram-widget>
        <!-- End Instagram Widget -->
        `
    },
    widgetList() {
      let widgets = this.$store.getters['igwidget/all']
      console.log(widgets)
      return this.$store.getters['igwidget/all']
    },

    totalWidgets() {
      const widgets = this.$store.getters['igwidget/all']
      return widgets ? widgets.length : 0
    },

    user() {
      return this.$store.getters['auth/user']
    },

    routeName() {
      return this.$route.name
    },
    visiblePostCount() {
      return (this.editor.layout.columns * this.editor.layout.rows) + this.additionalPostsToShow
    },
    visiblePosts() {
      const maxPosts = (this.editor.layout.columns * this.editor.layout.rows) + this.additionalPostsToShow
      const filteredPosts = this.posts.filter(post => post.media_url)
      return filteredPosts.slice(0, maxPosts)
    },
    imageCardPosts() {
      return this.visiblePosts.filter(post =>
          this.editor.post_style.post_style === 'image_card' && post.media_url
      );
    },
    expandedPosts(){
      return this.visiblePosts.filter(post =>
          this.editor.post_style.post_style === 'expanded' && post.media_url
      );
    },
    showLoadMore() {
      console.log(this.visiblePostCount)
      console.log(this.posts.length)
      return this.visiblePostCount < this.posts.length
    },
  },

  watch: {
    reload: (val) => {
      console.log(val)
    },
    'editor.styles.theme': function(newTheme) {
      if (newTheme === 'dark') {
        this.editor.styles.header_bg = '#1b1b1b'
        this.editor.styles.header_text = '#ffffff'
        this.editor.styles.follow_button_bg = '#333333'
        this.editor.styles.follow_button_text = '#ffffff'
        this.editor.styles.load_more_button_bg = '#333333'
        this.editor.styles.load_more_button_text = '#ffffff'
      } else if (newTheme === 'light') {
        this.editor.styles.header_bg = '#ffffff'
        this.editor.styles.header_text = '#000000'
        this.editor.styles.follow_button_bg = '#0081ff'
        this.editor.styles.follow_button_text = '#ffffff'
        this.editor.styles.load_more_button_bg = '#0081ff'
        this.editor.styles.load_more_button_text = '#ffffff'
      }
    },
  },

  created() {
    this.getWidgets()
  },

  mounted() {
    this.getWidgets()
  },

  methods: {
    formatNumber(value) {
      if (typeof value !== 'number' || isNaN(value)) {
        return value // Return as is if it's not a valid number
      }

      const thresholds = [
        { value: 1e9, suffix: 'B' },  // Billion
        { value: 1e6, suffix: 'M' },  // Million
        { value: 1e3, suffix: 'K' },   // Thousand
      ]

      for (const { value: threshold, suffix } of thresholds) {
        if (value >= threshold) {
          return (value / threshold).toFixed(1) + suffix
        }
      }

      return value.toString() // Return the number as a string if less than 1000
    },

    addWidget(widgetId) {
      this.selectedWidget = widgetId
      this.showAddWidgetModal = true
    },

    copyToClipboard() {
      navigator.clipboard.writeText(this.embedHTML.replaceAll('&lt;', '<'))
      Vue.$toast.open({
        message: 'Code Copied',
        type: 'success',
        duration: 5000,
      })
    },

    loadMore() {
      this.additionalPostsToShow += this.editor.layout.columns

      console.log(this.additionalPostsToShow)
    },

    getWidget(fetchProfile = false) {
      console.log({
        type:this.editor.source.type
      })
      const username = this.editor.source.username || 'k.mbappe';
      this.gettingWidget = true
      this.profileFetchState = 'fetching'

      const handleError = (message) => {
        console.log(message);
        Vue.$toast.open({
          message,
          type: 'error',
          duration: 5000,
        })
        console.error(message)
        this.gettingWidget = false
        this.profileFetchState = 'error'
        this.profileFetchMessage = message
        this.showEditWidgetModal = true
      }

      const handleSuccess = (res, postsType) => {
        console.log(res);
        if (res.businessDetails || res) {
          this.profile = res.businessDetails ?? null
          this.posts = res.businessDetails?.media.data || res
          this.postsType = postsType
          this.gettingWidget = false
          this.profileFetchState = 'success'
          if (!fetchProfile) {
            this.showWidgetModal()
          }
        } else {
          handleError('Invalid Username or You\'re trying to access a private account')
        }
      }

      const fetchIgWidget = () => {
        this.$store.dispatch('igwidget/getIgWidget', { username })
          .then((res) => handleSuccess(res, 'profile'))
          .catch(() => handleError('Invalid Username or You\'re trying to access a private account'))
      }

      const searchKeyword = () => {
        this.$store.dispatch('igwidget/searchKeyword', { username })
          .then((res) => handleSuccess(res, 'hashtag'))
          .catch(() => handleError('Invalid Username or You\'re trying to access a private account'))
      }
      if (this.editor.source.type === 'username') {
        fetchIgWidget()
      } else {
        searchKeyword()
      }
    },


    showWidgetModal() {
      this.showEditWidgetModal = true
    },

    post_action(post) {
      this.modalPost = post
      if (this.editor.post_style.post_action === 'instagram') {
        window.open(post.permalink, '_blank')
      } else {
        this.showPostModal = true
      }
    },

    getWidgets() {
      this.loadingWidgets = true

      this.$store
        .dispatch('igwidget/getAllWidgets', {
          queries: this.datatable.queries,
          page: 'index',
        })
        .then((res) => {
          this.loadingWidgets = false
          console.log(res)
          this.widgets = res
        })
        .catch((error) => {
          this.loadingWidgets = false
          console.error('Error fetching widgets:', error)
        })
    },

    async createWidget() {

      this.editor.editing = 'source'
      this.editor.source.username = ''
      this.profileFetchState = ''
      this.profile = {}
      this.posts = [{
        'id': '',
        'like_count': '',
        'comments_count': '',
        'caption': '',
        'media_url': '',
      }];
      this.gettingWidget = true
      console.log(this.newWidgetTitle)
      try {
        const response = await this.$store.dispatch('igwidget/createWidget', {
          editor: this.default_editor,
          widgetTitle: this.newWidgetTitle,
        })
        .then((resp) => {
          this.widgetId = resp.data.id;
          this.getWidget();
        });
        // this.getWidgets();
        this.showEditWidgetModal = true
        this.gettingWidget = false
        console.log('Widget created successfully:', response)

        // Close modal or handle success
        this.showCreateWidgetModal = false
      } catch (error) {
        this.loadingBusinesses = false
        console.error('Error creating widget:', error)

        // Handle error
      }
    },

    editWidget(business) {
      this.widgetId = business.id
      this.editor = JSON.parse(business.widget_settings)
      this.getWidget()
    },

    redirectToProfile() {
      if (this.profile && this.profile.username) {
        // Redirect to user's Instagram profile
        window.open(`https://www.instagram.com/${this.profile.username}`, '_blank')
      }
    },

    viewPage(widgetSettings) {
      this.gettingPage = true
      const settings = JSON.parse(widgetSettings.widget_settings)
      const username = settings.source.username.replace('@','');
      window.open(`https://www.instagram.com/${username}`, '_blank')
      this.gettingPage = false
    },

    async saveEditor(exit) {
      this.savingEditor = true

      try {
        const response = await this.$store.dispatch('igwidget/saveEditor', {
          editor: this.editor,
          widgetId: this.widgetId,
        })

        this.savingEditor = false
        console.log('Widget Saved Successfully:', response)
        this.getWidgets()

        // Close modal or handle success
        if (exit) {
          this.showEditWidgetModal = false
        }
      } catch (error) {
        this.savingEditor = false
        console.error('Error Saving Widget:', error)
      }
    },

    remove(business) {
      this.showDeleteModal = true
      this.selectedBusiness = business
      this.widgetId = business.id
    },

    handleRemove() {
      this.showDeleteModal = false
      this.loadingRemove = this.widgetId

      this.$store
        .dispatch('igwidget/deleteWidget', this.widgetId)
        .then(() => {
          this.loadingRemove = null
          this.getWidgets()
        })
        .catch(() => {
          this.loadingRemove = null
          console.error('Error removing widget')
        })
    },

    sortChanged(args) {
      this.datatable.queries.sort_by = args.sortBy
      this.datatable.queries.sort_desc = args.sortDesc
      this.datatable.queries.page = 1
      this.getWidgets()
    },

    onPageChanged(page) {
      this.datatable.queries.page = page
      this.getWidgets()
    },

    impersonate(userId) {
      this.loadingImpersonate = true

      this.$store
        .dispatch('auth/impersonate', userId)
        .then((res) => {
          this.$store
            .dispatch('auth/getProfile')
            .then((user) => {
              this.$router.push('/' + user.role.toLocaleLowerCase())
              this.loadingImpersonate = false
            })
            .catch(() => {
              this.loadingImpersonate = false
            })
        })
        .catch(() => {
          this.loadingImpersonate = false
        })
    },

  },
}
</script>
<style lang='scss' scoped>
.status-filter {
  text-transform: capitalize;
}
</style>
<style>
.pac-container.pac-logo {
    z-index: 10000000;
}

.customer-table td.actions {
    min-width: 200px;
    max-width: 200px;
}
</style>
